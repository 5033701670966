import React from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import TournamentGroupsOverview from 'src/components/tournament-groups-overview/tournament-groups-overview';
import { usePrivateRoute } from 'src/utils/auth';

const TournamentGroupsOverviewPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Tournament Groups" />
      <TournamentGroupsOverview />
    </Layout>
  );
};

export default TournamentGroupsOverviewPage;
